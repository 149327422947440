#wrapper {
  width: 100%;
  height: 400px;
  border: 1px solid black;
  display: flex;
  align-items: center ;
  justify-content: center;
}

#button {
  height: 20px;
  width: 100px;
}